<template>
    <div class="page-sidebar-container">
        <ul class="page-sidebar">
            <li v-if="user.hasPermission('users:user.search')" v-bind:class="setActiveSidebar('users.manager.browse')">
                <b-link :to="{name: 'users.manager.browse'}">Browse Users</b-link>
            </li>
            <li v-if="user.hasPermission('users:user.create')" v-bind:class="setActiveSidebar('users.manager.user.create')">
                <b-link :to="{name: 'users.manager.user.create'}">Create New User</b-link>
            </li>
            <!--
            <li v-bind:class="setActiveSidebar('users.manager.departments')">
                <b-link>Departments</b-link>
            </li>
            <li v-bind:class="setActiveSidebar('users.manager.permissions')">
                <b-link>Permissions</b-link>
            </li>

            <li v-bind:class="setActiveSidebar('users.manager.settings')">
                <b-link>Settings</b-link>
            </li>
            -->
        </ul>
    </div>
</template>

<script>
export default {
    props: ['active','user'],
    components: {

    },
    methods: {
        setActiveSidebar(name){
            return this.active === name ? 'active' : '';
        },
    },
};
</script>
