<template>
    <div id="team">
      <PageMenu :breadcrumbs="breadcrumbs" :page="page"/>
      <div class="page-with-menu">
          <b-row>
            <b-col cols="4">
              <SidebarManager active="users.manager.browse" :user="user"/>
            </b-col>
            <b-col cols="8">
              <div v-if="!users.isLoading">
                <UsersList v-if="users" :users="users" />
              </div>
              <b-spinner v-else label="Loading..." small></b-spinner>
            </b-col>
          </b-row>
      </div>
    </div>
</template>

<script>
import PageMenu from '@/common/components/PageMenu';
import SidebarManager from './../../components/Sidebar.Manager';
import UsersList from './../../components/lists/Users.List';
export default {
  props: ['user'],
    components: {
      PageMenu,
      SidebarManager,
      UsersList
    },
    data(){
        return {
          page: {
              title: "My Team",
          },
          breadcrumbs: [
              {
                  title: "Dashboard",
                  url: this.$router.resolve({name: "app.dashboard"}).href
              },
              {
                  title: "My Team",
                  url: this.$router.resolve({name: "users.manager.browse"}).href
              },
              {
                  title: "Browse Users",
              }
          ],
          users: {
            isLoading: true,
            records: [],
            total: 0,
            pagination: {}
          }
        };
    },

    methods: {
      getUsers(pageNo=1){
        this.users.isLoading = true;
        this.$api.get('users', {params: {perPage: 100, page: pageNo}}).then(res => {
          this.users.isLoading = false;
          let {records} = res.data;
          this.users.records = records;
        });
      },
    },

    mounted(){
      this.getUsers(1);
    },
};
</script>
