<template>
  <b-row cols-lg="3" cols-md="2" cols-sm="1">
      <b-col v-for="(item, key) in users.records" :key="key">
          <b-card class="mb-3">
              <b-card-text>
                  <div class="d-flex justify-content-center mb-3">
                      <img v-if="item.profileImage" src="https://picsum.photos/100/100/?image=41" class="rounded-circle">
                      <div v-else class="rounded-circle" style="width: 100px; height: 100px; background: #23B5DB; border: 2px solid #23B5DB;">
                          <div class="d-flex justify-content-center" style="padding-top: 32px; font-size: 24px; font-weight: 700; color: #fff;">
                              {{ getInitials(item) }}
                          </div>
                      </div>
                  </div>
                  <div class="d-flex justify-content-center">
                      <strong>{{ item.firstName }} {{ item.lastName }}</strong>
                  </div>
                  <!--
                  <div class="d-flex justify-content-center">
                      Techical department
                  </div>
                  -->
                  <div class="d-flex justify-content-center mt-3">
                      <b-link :to="{name: 'users.manager.user', params: {userId: item.id}}" class="btn btn-light btn-sm">
                          <BIconPencilSquare/> Manage
                      </b-link>
                  </div>
              </b-card-text>
          </b-card>
      </b-col>
  </b-row>
</template>

<script>
import {BIconPencilSquare} from 'bootstrap-vue';
//import PaginationRouter from '@/common/components/Pagination.Router';

export default {
    props: ['users'],
    components: {
        BIconPencilSquare,
        //PaginationRouter
    },
    methods: {
        getInitials(item){
            let initials = "";
            if(item.firstName){
                initials += item.firstName[0];
            }

            if(item.lastName){
                initials += item.lastName[0];
            }

            return initials;
        }
    }
};
</script>
